<template>
  <div class="service-price">
    <Header></Header>
    <div class="main">
      <div class="flex flex-row align-center price-title">
        <img src="../assets/servePrice/price.png" alt="" />
        <span>服务报价表</span>
      </div>
      <div class="chart-wrap">
        <img src="../assets/home/loginBg.png" alt="" />
        <div class="con">
        <div class="con_center" style="font-size: 16px">
          <div class="bm-con">
            <p
              style="
                white-space: normal;
                word-spacing: 0px;
                text-transform: none;
                color: rgb(0, 0, 0);
                text-align: left;
                font: 10.5pt Calibri;
                margin: 0pt;
                widows: 1;
                letter-spacing: normal;
                text-indent: 225pt;
                -webkit-text-stroke-width: 0px;
              "
            >
              &nbsp;
            </p>
            <p style="text-align: center">
              <span
                style="font-size: 22pt; font-family: 黑体; font-weight: bold"
              >
                <font face="黑体">内江汽车服务有限公司服务报价表</font> </span
              ><span style="font-size: 18pt; font-family: 黑体">
                <!-- <?xml:namespace prefix = "o" /><o:p ></o:p> -->
              </span>
            </p>
            <p
              style="
                white-space: normal;
                word-spacing: 0px;
                text-transform: none;
                color: rgb(0, 0, 0);
                text-align: justify;
                font: 10.5pt Calibri;
                margin: 0pt;
                widows: 1;
                letter-spacing: normal;
                text-indent: 171pt;
                -webkit-text-stroke-width: 0px;
              "
            >
              <span style="font-size: 18pt; font-family: 黑体">&nbsp;</span>
            </p>
            <p></p>
            <table class="MsoTableGrid" style="margin: 0 auto; width: 99%">
              <tbody>
                <tr>
                  <td rowspan="2">
                    <p><span>车型</span></p>
                  </td>
                  <td colspan="2">
                    <p><span>车辆运行费</span></p>
                  </td>
                  <td colspan="5">
                    <p><span>第三方劳务费用</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>车辆运行费用</span></p>
                    <p><span>（元/天）</span></p>
                  </td>
                  <td>
                    <p><span>基本定额里程</span></p>
                    <p><span>（公里/天）</span></p>
                  </td>
                  <td>
                    <p><span>驾驶员劳务（元/天）</span></p>
                    <p><span>含：基本劳务费、8小时/天</span></p>
                    <p><span>工作、票税金</span></p>
                  </td>
                  <td colspan="2">
                    <table
                      style="
                        width: 100%;
                        height: 58px;
                        margin: 0;
                        padding: 0;
                        border: 0;
                      "
                    >
                      <tr>
                        <td
                          colspan="2"
                          style="border-top: 0; border-left: 0; border-right: 0"
                        >
                          <p><span>只租用驾驶员</span></p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            width: 150px;
                            border-left: 0;
                            border-bottom: 0px;
                          "
                        >
                          <p><span>市区及县区</span></p>
                        </td>
                        <td
                          style="
                            width: 150px;
                            border-right: 0;
                            border-bottom: 0px;
                          "
                        >
                          <p><span>跨市</span></p>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td width="150">
                    <p><span>餐费</span></p>
                    <p><span>（元/天，单餐20元/次）</span></p>
                  </td>
                  <td width="150">
                    <p><span>住宿</span></p>
                    <p><span>（元/天）</span></p>
                    <p><span> </span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>日产轩逸</span></p>
                  </td>
                  <td>
                    <p><span>170</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td style="width: 80px">
                    <p><span>300</span></p>
                  </td>
                  <td style="width: 80px">
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                  <td rowspan="17">
                    <p><span>住宿费标准300元/人（如超过报销标准与用车单位协商签字确认后方可报销）</span></p>
                  </td>
                </tr>
                <!-- <tr>
                  <td>
                    <p><span>帕萨特</span></p>
                  </td>
                  <td>
                    <p><span>230</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>丰田凯美瑞</span></p>
                  </td>
                  <td>
                    <p><span>230</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr> -->
                <tr>
                  <td>
                    <p><span>本田雅阁</span></p>
                  </td>
                  <td>
                    <p><span>200</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>广汽新能源</span></p>
                  </td>
                  <td>
                    <p><span>200</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>红旗EQM5新能源</span></p>
                  </td>
                  <td  colspan="6">
                    <p><span>带人带车300公里内500元/天、单独租车300公里内260元/天（均不含过路费）。</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>新迈腾尊享型</span></p>
                  </td>
                  <td>
                    <p><span>310</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>新红旗H7</span></p>
                  </td>
                  <td>
                    <p><span>310</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>丰田亚洲龙</span></p>
                  </td>
                  <td>
                    <p><span>310</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>新丰田凯美瑞</span></p>
                  </td>
                  <td>
                    <p><span>340</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>汉兰达（越野）</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr><tr>
                  <td>
                    <p><span>丰田汉兰达18款</span></p>
                  </td>
                  <td>
                    <p><span>420</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>新汉兰达18款</span></p>
                  </td>
                  <td>
                    <p><span>500</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <!-- <tr>
                  <td>
                    <p><span>本田商务</span></p>
                  </td>
                  <td>
                    <p><span>270</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr> -->
                <tr>
                  <td>
                    <p><span>长安欧尚商务</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <b><span>新别克GL8ES商务</span></b>
                    </p>
                  </td>
                  <td>
                    <p><span>420</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <b><span>新别克商务GL8陆上公务舱</span></b>
                    </p>
                  </td>
                  <td>
                    <p><span>500</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>240</span></p>
                  </td>
                  <td>
                    <p><span>300</span></p>
                  </td>
                  <td>
                    <p><span>350</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>考斯特客车（17、22座）</span></p>
                  </td>
                  <td>
                    <p><span>600</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>280</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>450</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>现代康恩迪（18座）</span></p>
                  </td>
                  <td>
                    <p><span>480</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>280</span></p>
                  </td>
                  <td>
                    <p><span>400</span></p>
                  </td>
                  <td>
                    <p><span>450</span></p>
                  </td>
                  <td>
                    <p><span>50</span></p>
                  </td>
                </tr>
                <tr>
                  <td colspan="1">
                    <p><span>金旅（30座/39座/48座等）</span></p>
                  </td>
                  <td colspan="7">
                    <p><span>根据用车时间地点议价确定</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p><span>其它车型</span></p>
                  </td>
                  <td width="890" colspan="7">
                    <p>
                      <span
                        >公司拥有各类丰富车型，如需本表外车型请致电0832-2032666，我们将满足用户各类用车需求。</span
                      >
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              <br />
            </p>
            <div style="text-align: left">
              <br />注：一.燃油费及能耗费
              <br />1.燃油费：根据内江地区中石油对外公布油价实时调整收取。收取标准根据综合油耗核算。
              <br />（1）轿车：百公里油耗常规收费按11升/百公里核算，夏季（7-9月）按13升/百公里核算；其中轩逸轿车百公里油耗常规收费按10升/百公里核算，夏季（7-9月）按11升/百公里核算。
              <br />（2）商务车、越野车：常规标准17升/百公里；夏季标准20升/百公里。
              <br />（3）客车：常规标准25升/百公里；夏季标准30升/百公里。
              <br />2.能耗费：新能源车300公里以内包干价收取；超过300公里以后，按超出公里数0.3元/公里计收用车单位能耗成本。
              <br />3.用车单位自行补充电产生的充电费、油费，根据用车单位情况，可选择代收代付(含税费)或用车单位自行处理费用两种方式。
              <br />二．新能源车车辆租赁费
              <br />（1）带人带车500元/天（包括驾驶员代驾费及车辆租赁费）；
              <br />（2）自带车辆260元/天。
              <br />三．过路过桥费
              <br />按实际发生（支付）金额收取。
              <br />四．实际产生的基本费用包括过路过桥费、停车费、住宿、餐费等。
              <br />1.过路过桥费：按实际发生（支付）金额收取。
              <br />2.停车费：按实际发生（支付）金额收取。
              <br />3.住宿及餐费：住宿费、用餐费由用车单位提供。住宿费标准300元/人（如超过报销标准经用车确认后方可执行），用餐费按50元/天标准结算，（早餐10元，午餐、晚餐按20元标准执行）。
              <br />五．其他事项
              <br />本方案自2024年7月1日起执行，原公务用车收费标准作废。如满足条件，可与其他优惠政策同时享受。
            </div>
            <div style="text-align: left" class="hint">
              <h3>尊敬的客户单位:</h3>
              <p>为了保障乘车人的安全，根据《中华人民共和国道路运输条例》和《四川省道路运输条例》的相关规定，请用车单位合理安排用车时间，防止驾驶员疲劳驾驶，时间最好每天控制在8个小时，里程控制在400公里。特此告知！内江汽车服务有限公司</p>
              <span>内江汽车服务有限公司</span>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "ServicePrice",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    loadData() {},
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
/* 之前的表格样式 */
p{
    margin: 0;
}
table{border-collapse:collapse;}
.con {
  width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
  /* min-height: 990px; */
  padding: 0px;
  background: #fff;
  margin-bottom: 10px;
  overflow: hidden;
}

.con_left {
  float: left;
}

.con_center {
  float: left;
  padding: 30px;
}

td {
  border: 1pt solid #000000;
}

span {
  font-size: 9pt;
  text-align: center;
}

p {
  text-align: center;
}
/* 当下 */
.service-price{
    background-color: #F5F7FA;
}
.main{
    width: 1200px;
    margin: 0 auto;
    background-color: #FFFFFF;
}
.price-title{
    padding: 18px 0;
    position: relative;
    background-color: #F5F7FA;
}
.price-title::after{
    content: '';
    width: 1072px;
    height: 1px;
    background-color: #DADADA;
    position: absolute;
    left: 135px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}
.price-title img{
    margin-right: 2px;
}
.price-title span{
    font-size: 18px;
    font-weight: 400;
    color: #141414;
}
.chart-wrap{
    padding-top: 13px;
    box-shadow: 1px 5px 9px 1px rgba(0, 0, 0, 0.18);
}
.chart-wrap>img{
    width: 1174px;
    height: 175px;
}
.hint{
  margin-top: 30px;
}
.hint h3{
  margin-bottom: 10px;
}
.hint h3,.hint p,.hint span{
  font-size: 16px;
  color: #fa3e30;
}
.hint p{
  text-align: left;
}
.hint span{
  display: block;
  text-align: right;
}
</style>
